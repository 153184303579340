import './App.css';
import Confetti from 'react-confetti';
import { YMaps, Map, Placemark } from 'react-yandex-maps';
import kirill from './kirill.mp4';
import sasha from './sasha.mp4';
import ira from './ira.mp4';
import gipsies from './show/gipsies.jpg'
import pig from './show/pig.jpg'
import trans from './show/trans.jpg'
import zhivot from './show/zhivot.jpg'
import surprise from './show/surprise.jpg'
import map from './map.jpg'
function App() {
  const shows = [
    {name: 'Роскошная тамада', img: trans},
    {name: 'Цыганский ансамбль', img: gipsies},
    {name: 'Танец живота', img: zhivot},
    {name: 'Свинка-стриптизерша', img: pig},
    {name: 'И многое другое?', img: surprise},
  ]
  return (
    <div className='App'>
      <Confetti gravity={0.02} numberOfPieces={100} />
      <h1>
        Официальное приглашение <br /> на три дня рождения в один день!
      </h1>
      <h2 style={{color: 'red', textAlign: 'center', textTransform: 'uppercase'}}>место проведения изменилось</h2>
      <a style={{color: 'blue', textAlign: 'center', textTransform: 'uppercase', display: 'block', margin: '0 auto 12px'}} href="https://yandex.ru/maps/-/CCUa4DG7lA">ОТКРЫТЬ КАРТЫ</a>
      <div className='App__video-carousel'>
        <div className='App__video-slide'>
          <video width='179px' height='179px' autoPlay allow='autoplay'  loop muted>
            <source src={sasha} type='video/mp4' />
          </video>
          <p className='App__paragraph'>Сашуля</p>
        </div>
        <div className='App__video-slide'>
          <video width='179px' height='179px' allow='autoplay' autoPlay loop muted>
            <source src={ira} type='video/mp4' />
          </video>
          <p className='App__paragraph'>Ирочка</p>
        </div>
        <div className='App__video-slide App__video-slide--last'>
          <video width='179px' height='179px' allow='autoplay' autoPlay loop muted>
            <source src={kirill} type='video/mp4' />
          </video>
        <p className='App__paragraph'>Кирюша</p>

        </div>
      </div>

      <div className='App__entrance-rules'>
        <p>
          ЕСЛИ ЧТО ЗВОНИТЕ САШУЛЕ (
          <span>
            <a target='_blank' rel='noreferrer' href='tel: 89859302769'>
              89859302769
            </a>
          </span>
          )
        </p>
        <p>
          ИЛИ КИРЮШЕ (
          <span>
            <a target='_blank' rel='noreferrer' href='tel: 89261928566'>
              89261928566
            </a>
          </span>
          )
        </p>
        <p>
          ИЛИ ИРОЧКЕ (
          <span>
            <a target='_blank' rel='noreferrer' href='tel: 89856808267'>
              89856808267
            </a>
          </span>
          )
        </p>
        <p>
          ИЛИ ПИШИТЕ В ВК{' '}
          <span>
            <a
              target='_blank'
              rel='noreferrer'
              href='https://vk.com/alexershik'
            >
              САШУЛЕ
            </a>
          </span>{' '}
          <span>
            <a href='https://vk.com/dysleixc'>КИРЮШЕ</a>
          </span>{' '}
          <span>
            <a href='https://vk.com/irabalkova'>ИРОЧКЕ</a>
          </span>
        </p>
        
      </div>

      <h2>Вас ждут:</h2>
      <div className='bar-carousel'>
        <div className='bar-carousel__slide'>
          <img
            className='bar-carousel__img'
            src='https://images.unsplash.com/photo-1483577910943-59decea62c35?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1789&q=80'
            alt=''
          />
          <div className='bar-carousel__text'>
            <span role='img' aria-label='man'>
              🕺
            </span>
            <span role='img' aria-label='woman'>
              💃
            </span>{' '}
            Шоу-программа
          </div>
        </div>
        <div className='bar-carousel__slide'>
          <img
            className='bar-carousel__img'
            src='https://www.loft2rent.ru/upload_data/2020/1894/upldkuebsy.jpg.1200x800.jpg'
            alt=''
          />
          <div className='bar-carousel__text'>
            <span role='img' aria-label='window'>
              🗔
            </span>{' '}
            Большие окна
          </div>
        </div>
        <div className='bar-carousel__slide'>
          <img
            className='bar-carousel__img'
            src='https://www.loft2rent.ru/upload_data/2020/35/upld7pcwJW.jpg.1200x800.jpg'
            alt=''
          />
          <div className='bar-carousel__text'>
            <span role='img' aria-label='coffee'>
              ☕
            </span>{' '}
            Кайфодром
          </div>
        </div>
        <div className='bar-carousel__slide'>
          <img
            className='bar-carousel__img'
            src='https://www.loft2rent.ru/upload_data/2020/6730/upld4MPNja.jpg.1200x800.jpg'
            alt=''
          />
          <div className='bar-carousel__text'>
            <span role='img' aria-label='sofa'>
              💺
            </span>{' '}
            Диваны
          </div>
        </div>
        <div className='bar-carousel__slide'>
          <img
            className='bar-carousel__img'
            src='https://www.loft2rent.ru/upload_data/2020/3421/upld4qdGAW.JPG.1200x800.jpg'
            alt=''
          />
          <div className='bar-carousel__text'>
            <span role='img' aria-label='cocktail'>
              🍹
            </span>
            <span role='img' aria-label='Wine'>
              🍷
            </span>{' '}
            Барная стойка
          </div>
        </div>
        <div className='bar-carousel__slide bar-carousel__slide--last'>
          <img
            className='bar-carousel__img'
            src='https://www.loft2rent.ru/upload_data/2020/3585/upldEGcCC6.JPG.1200x800.jpg'
            alt=''
          />
          <div className='bar-carousel__text'>
            <span role='img' aria-label='light'>
              💡{' '}
            </span>
            <span role='img' aria-label='music'>
              🎵
            </span>{' '}
            Светомузыка
          </div>
        </div>
      </div>
      <h2>В шоу-программе:</h2>
        <div className="show-carousel">
          {shows.map((item,index) => <div key={item.name} className={`show ${index === shows.length - 1 ? 'show--last': ''}`}>
            <img className='show__img' src={item.img} alt="" />
            <div>{item.name}</div>
          </div>)}
        </div>
      <h2>Место проведения: лофт Loft Krov</h2>
      <h2>Ул.Самокатная дом 4 строение 32</h2>
      <img src={map} width='100%' height='auto' alt="" />
      <YMaps>
        <Map
          width='100%'
          defaultState={{ center: [55.755525, 37.675621], zoom: 15 }}
        >
          <Placemark geometry={[55.755525, 37.675621]} />
        </Map>
      </YMaps>
      
      <h2>Правила проведения:</h2>
      <ul className='App__ul'>
        <li className='App__li'>Вход по спискам</li>
        <li className='App__li'>Алкоголь можно принести свой (нужно)</li>
        <li className='App__li'>флипчарт</li>
        <li className='App__li'>Микроволновка</li>
        <li className='App__li'>Холодильник</li>
        <li className='App__li'>
          КУРИТЬ В ВЫДЕЛЕННЫХ ДЛЯ КУРЕНИЯ МЕСТАХ!!! ШТРАФ 10000 рублей
        </li>
      </ul>
    </div>
  );
}

export default App;
